import 'cookieconsent'

document.addEventListener('DOMContentLoaded', () => {
  const { cookieConsent: content, locale } = window.Spanisimo.rails;
  if (locale !== 'ja') return // 用意ができるまで英語版はクッキー確認出さない

  const option = { // https://www.osano.com/cookieconsent/documentation/javascript-api/
    palette: {
      popup: { background: '#000' },
      button: { background: 'transparent', border: '#3ABB17', text: '#3ABB17' },
    },
    content
  }
  cookieconsent.initialise(option)
})
